import React from "react"
import "./index.scss"
import Layout from "../components/layout"

export default () => (
  <Layout pageId={"/community"}>
    <div className="container-fluid bg-wave-top py-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <h1 style={{color:'white', fontWeight:'bold', fontStyle:'italic'}}>住宅社区</h1>
            <p className="text-white">
              随着地产开速度放缓以及新政策对地厂商自持物业的要求，地产与物业企业正在寻求信息化突破。物业是连接小区居民、外部服务以及基层社区、乡镇政府的桥梁。智慧物业正在成为未来社区管理的基础石。典型的小区物业公司组织架构包括办公室、客服部、工程部、保安部、保洁部等。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/homepage/3_1.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            收费管理
          </h1>
          <p>
            收费项目定义、收费单智能生成，催缴管理，住户物业费缴纳尽在掌控。通过对住户信息全面建档，并结合线上缴费，可以有效地提升物业费收缴率
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/community/5.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              报修管理
            </h1>
            <p>
              1000户的社区，平均每天要有30-50起住户报修事件。报修事件是小区住户与物业管理方频次最高的接触互动，物业人员对报修事件的响应速度与处理结果直接反应了住户对物业服务水平的感知。通过手机端报修，并对报修事件、处理结果、住户满意度评价全程跟踪统计可以对一线员工形成有效的监督并极大地提升管理效率与服务品质。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/community/2.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            通行管理
          </h1>
          <p>
            通过智能门禁、人脸识别门禁、楼宇对讲、访客管理等软硬件系统的使用，可以提高社区通行管理效率、提升住户安全感、并给住户带来极大便利。
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/park/4.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              通知管理
            </h1>
            <p>
              小区通知、社区公告、三方信息等通过短信、微信、手机APP高效触达
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/community/3.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center" py-md-5>
        <div className="col-12 col-md-6">
          <h1>
            设备巡检
          </h1>
          <p>
            设备定期巡检、保养是物业管理的最重要的事项之一。也是住户最能感知物业服务的窗口之一。通过设备信息化台账的管理和设备巡检保养计划的制定，再加之智能设备的应用，可以全面跟踪设备巡检工作的履行情况，提升社区服务品质。
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/community/4.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              安保巡更
            </h1>
            <p>
              手机巡更，数据实时上传，异常情况报告，人脸识别抽查，防止他人代替；数据汇总统计
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/3.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
